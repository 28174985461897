import * as Axios from "../libs/Axios/Axios";

const path = "/propuesta";

export default class Propuesta {
  static add(tipo, asegurados) {
    const params = { tipo, asegurados };
    console.log(params);
    return Axios.post(path, params, { responseType: "blob" });
  }
}
