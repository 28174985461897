import React from "react";
import InputView from "./InputView";

export default class Input extends React.Component {
  render() {
    return (
      <InputView
        name={this.props.name}
        label={this.props.label}
        type={this.props.type}
        help={this.props.help}
        onChange={this.props.onChange}
        value={this.props.value}
        autocomplete="sinauto"
      />
    );
  }
}
