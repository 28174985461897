import React from "react";
import NuevaPropuestaView from "./NuevaPropuestaView";
import Propuesta from "../../services/Propuesta";

const asegurado_clean = {
  nombres: "",
  edad: "0",
  costo_la_positiva: "0",
  costo_rimac: "0",
  costo_pacifico: "0",
  costo_mapfre: "0",
};

const tipos = [
  { value: 1, description: "AMF MEDSALUD" },
  { value: 2, description: "AMF PLUS" },
  { value: 3, description: "AMF PREMIUM" },
  { value: 4, description: "AMF INTERNACIONAL" },
];

export default class NuevaPropuesta extends React.Component {
  constructor(props) {
    super(props);
    this.initEvents();
    this.state = {
      tipo: undefined,
      asegurado: asegurado_clean,
      asegurados: [],
    };
  }

  render() {
    return (
      <NuevaPropuestaView
        module={this.state.module}
        asegurado={this.state.asegurado}
        asegurados={this.state.asegurados}
        tipos={tipos}
        onChangeNombres={this.handleChangeNombres}
        onChangeEdad={this.handleChangeEdad}
        onChangeCostoLaPositiva={this.handleChangeCostoLaPositiva}
        onChangeCostoRimac={this.handleChangeCostoRimac}
        onChangeCostoPacifico={this.handleChangeCostoPacifico}
        onChangeCostoMapfre={this.handleChangeCostoMapfre}
        onClickAgregar={this.handleClickAgregar}
        onClickGenerar={this.handleClickGenerar}
        onChangeTipo={this.handleChangeTipo}
      />
    );
  }

  initEvents() {
    this.handleChangeNombres = this.handleChangeNombres.bind(this);
    this.handleChangeEdad = this.handleChangeEdad.bind(this);
    this.handleChangeCostoLaPositiva =
      this.handleChangeCostoLaPositiva.bind(this);
    this.handleChangeCostoRimac = this.handleChangeCostoRimac.bind(this);
    this.handleChangeCostoPacifico = this.handleChangeCostoPacifico.bind(this);
    this.handleChangeCostoMapfre = this.handleChangeCostoMapfre.bind(this);
    this.handleClickAgregar = this.handleClickAgregar.bind(this);
    this.handleClickGenerar = this.handleClickGenerar.bind(this);
    this.handleChangeTipo = this.handleChangeTipo.bind(this);
  }

  handleChangeNombres(evt) {
    this.setState({
      asegurado: {
        ...this.state.asegurado,
        nombres: evt.target.value,
      },
    });
  }

  handleChangeEdad(evt) {
    this.setState({
      asegurado: {
        ...this.state.asegurado,
        edad: Number(evt.target.value),
      },
    });
  }

  handleChangeCostoLaPositiva(evt) {
    this.setState({
      asegurado: {
        ...this.state.asegurado,
        costo_la_positiva: Number(evt.target.value),
      },
    });
  }

  handleChangeCostoRimac(evt) {
    this.setState({
      asegurado: {
        ...this.state.asegurado,
        costo_rimac: Number(evt.target.value),
      },
    });
  }

  handleChangeCostoPacifico(evt) {
    this.setState({
      asegurado: {
        ...this.state.asegurado,
        costo_pacifico: Number(evt.target.value),
      },
    });
  }

  handleChangeCostoMapfre(evt) {
    this.setState({
      asegurado: {
        ...this.state.asegurado,
        costo_mapfre: Number(evt.target.value),
      },
    });
  }

  handleClickAgregar(evt) {
    evt.preventDefault();
    this.setState({
      asegurados: [...this.state.asegurados, this.state.asegurado],
      asegurado: asegurado_clean,
    });
  }

  handleChangeTipo(idTipo) {
    console.log(idTipo);
    this.setState({
      tipo: idTipo,
    });
  }

  async handleClickGenerar(evt) {
    const response = await Propuesta.add(
      this.state.tipo,
      this.state.asegurados
    );
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "propuesta.pdf");
    document.body.appendChild(link);
    link.click();
  }
}
