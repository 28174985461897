import React from "react";
import ButtonView from "./ButtonView";

export default class Button extends React.Component {
  render() {
    return (
      <ButtonView onClick={this.props.onClick}>
        {this.props.children}
      </ButtonView>
    );
  }
}
