import React from "react";
import SelectView from "./SelectView";

export default class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      options: this.props.options,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  render() {
    return (
      <SelectView
        className={this.props.className}
        label={this.props.label}
        value={this.state.value}
        options={this.state.options}
        onChange={this.handleChange}
      />
    );
  }

  handleChange(evt) {
    this.setState({ value: evt.target.value }, () =>
      this.props.onChange(this.state.value)
    );
  }
}
