import React from "react";

export default class SelectView extends React.Component {
  render() {
    return (
      <select
        className={"form-select " + this.props.className}
        value={this.props.value}
        defaultValue={-1}
        onChange={this.props.onChange}
      >
        <option value="-1">{this.props.label}</option>
        {this.getOptions()}
      </select>
    );
  }

  getOptions() {
    return this.props.options.map((elm) => (
      <option key={elm.value} value={elm.value}>
        {elm.description}
      </option>
    ));
  }
}
