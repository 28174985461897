import React from "react";

export default class InputView extends React.Component {
  render() {
    return (
      <div className="mb-3">
        <label htmlFor={this.props.name} className="form-label">
          {this.props.label}
        </label>
        <input
          type={this.props.type}
          className="form-control"
          id={this.props.name}
          onChange={this.props.onChange}
          value={this.props.value}
        />
        <div className="form-text">{this.props.help}</div>
      </div>
    );
  }
}
