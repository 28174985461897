import React from "react";

export default class TableView extends React.Component {
  render() {
    return (
      <table className={"table"}>
        <thead>
          <tr>{this.getTableHeader()}</tr>
        </thead>
        <tbody>{this.getTableRows()}</tbody>
      </table>
    );
  }

  getTableHeader() {
    return this.props.header.map((elm, idx) => (
      <th key={"tbl-th-" + idx}>{elm.content}</th>
    ));
  }

  getTableRows() {
    return this.props.rows.map((row, row_idx) => {
      const cols = this.props.header.map((elm, col_idx) => {
        if (elm.key === "$id") {
          return (
            <td key={"tr-" + row_idx + "tr-" + col_idx}>#{row_idx + 1}</td>
          );
        } else {
          return (
            <td key={"tr-" + row_idx + "tr-" + col_idx}>{row[elm.key]}</td>
          );
        }
      });
      return <tr key={"tr-" + row_idx}>{cols}</tr>;
    });
  }
}
