const axios = require("axios");

// const service = 'https://ws.beta.avp.pe'
const service = "https://api.mcc.com.pe";

const getStrParams = (params) => {
  let str = "?";
  if (params !== undefined) {
    params.forEach((param) => {
      str += `${param.key}=${param.value}`;
    });
  } else {
    str = "";
  }
  return str;
};

const get = async (path, params) => {
  const str_params = getStrParams(params);
  const url = service + path + str_params;
  const result = await axios.get(url);
  return result.data;
};

const post = async (path, params, options = undefined) => {
  const url = service + path;
  const result = await axios.post(url, params, options);
  return result.data;
};

const put = async (path, params) => {
  const url = service + path;
  const result = await axios.put(url, params);
  return result.data;
};

const del = async (path, params) => {
  const str_params = getStrParams(params);
  const url = service + path + str_params;
  const result = await axios.delete(url);
  return result.data;
};

export { get, post, put, del };
