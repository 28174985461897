import React from "react";
import Input from "../Input/Input";
import Button from "../Button/Button";
import Table from "../Table/Table";
import Select from "../Select/Select";

export default class NuevaPropuestaView extends React.Component {
  render() {
    const tableHeaders = [
      { key: "$id", content: "N°" },
      { key: "nombres", content: "Nombres y Apellidos" },
      { key: "edad", content: "Edad" },
      { key: "costo_la_positiva", content: "La Positiva" },
      { key: "costo_rimac", content: "Rimac" },
      { key: "costo_pacifico", content: "Pacifico" },
      { key: "costo_mapfre", content: "Mapfre" },
    ];

    return (
      <div className="container">
        <div className="row">
          <div className="col-4 pt-5 pb-5">
            <div className="container mb-4 title-block">
              <h3>Nueva Propuesta</h3>
            </div>
            <div className="container">
              <form>
                <Select
                  className="mb-3"
                  label="Seleccione un tipo"
                  options={this.props.tipos}
                  onChange={this.props.onChangeTipo}
                />
                <Input
                  name="nombres"
                  label="Nombres y Apellidos"
                  type="text"
                  help="Nombres y apellidos del asegurado o dependiente"
                  onChange={this.props.onChangeNombres}
                  value={this.props.asegurado.nombres}
                />
                <Input
                  name="edad"
                  label="Edad"
                  type="number"
                  help="Edad del asegurado o dependiente"
                  onChange={this.props.onChangeEdad}
                  value={this.props.asegurado.edad}
                />
                <Input
                  name="la-positiva"
                  label="Costo - La Positiva"
                  type="number"
                  help="Costo por el seguro en la aseguradora, La Positiva"
                  onChange={this.props.onChangeCostoLaPositiva}
                  value={this.props.asegurado.costo_la_positiva}
                />
                <Input
                  name="rimac"
                  label="Costo - Rimac"
                  type="number"
                  help="Costo por el seguro en la aseguradora, Rimac"
                  onChange={this.props.onChangeCostoRimac}
                  value={this.props.asegurado.costo_rimac}
                />
                <Input
                  name="pacifico"
                  label="Costo - Pacifico"
                  type="number"
                  help="Costo por el seguro en la aseguradora, Pacifico"
                  onChange={this.props.onChangeCostoPacifico}
                  value={this.props.asegurado.costo_pacifico}
                />
                <Input
                  name="mapfre"
                  label="Costo - Mapfre"
                  type="number"
                  help="Costo por el seguro en la aseguradora, Mapfre"
                  onChange={this.props.onChangeCostoMapfre}
                  value={this.props.asegurado.costo_mapfre}
                />
                <Button type="primary" onClick={this.props.onClickAgregar}>
                  Agregar
                </Button>
              </form>
            </div>
          </div>
          <div className="col-8 pt-5 pb-5">
            <Table header={tableHeaders} rows={this.props.asegurados} />
            {this.props.asegurados.length > 0 ? (
              <Button type="primary" onClick={this.props.onClickGenerar}>
                Generar Propuesta
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
